import React, { FunctionComponent, useEffect } from 'react';
import { Vendors } from './vendors';

type HorizontalRulerProps = {
  icon: string;
};

export const IconImage: FunctionComponent<HorizontalRulerProps> = ({
  icon,
}) => {
  const iconStyle = {
    backgroundImage: `url('icons/${icon}.png')`,
  };

  return <div className={'icon'} style={iconStyle} />;
};
