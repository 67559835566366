import React, { FunctionComponent, useEffect } from 'react';
import { Vendors } from './vendors';
import { IconImage } from './iconImage'

type HorizontalRulerProps = {
  icon: string;
};

export const HorizontalRuler: FunctionComponent<HorizontalRulerProps> = ({
  icon,
}) => {
  return (
    <div className={'horizontal-ruler'}>
      <IconImage icon={icon} />
      <hr />
    </div>
  );
};
