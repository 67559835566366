import React, { FunctionComponent, useEffect, useState } from 'react';
import { HorizontalRuler } from './horizontalRuler';
import Typewriter, { TypewriterClass } from 'typewriter-effect';
import { InView } from 'react-intersection-observer';
import { isMobile } from 'react-device-detect'

interface SectionProps {
  id: string;
  title: string;
  childrenClassNames?: string;
  children?: any;
}

export const PageSection: FunctionComponent<SectionProps> = ({ id, title, childrenClassNames, children }) => {
  const [isShown, setIsShown] = useState(isMobile);

  let typewriterRef: TypewriterClass;

  return (
    <InView
      as="div"
      onChange={(inView, entry) => {
        if (inView && !isShown) {
          setIsShown(true);
          typewriterRef?.typeString(title).start();
        }
      }}
    >
      <HorizontalRuler icon={id} />
      <div className={'page-section-container'}>
        <section className={`page-section`}>
          <Typewriter
            options={{
              strings: title,
              wrapperClassName: `title ${isMobile}`,
              cursorClassName: 'title-cursor',
              autoStart: isMobile,
            }}
            onInit={typewriter => (typewriterRef = typewriter)}
          />
          <div className={`children ${childrenClassNames}`}>{children}</div>
        </section>
      </div>
    </InView>
  );
};
