import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { Link } from 'gatsby';

function getTogetherYears(): number {
  const now = moment();
  const gotTogether = moment([2015, 0, 16]);

  return now.diff(gotTogether, 'years');
}

export const Footer: FunctionComponent = () => {
  const [isShown, setIsShown] = useState('');
  const lineBreak = isMobile ? <br /> : ' | ';
  const togetherYears = getTogetherYears();

  return (
    <div className={'footer'}>
      <span>
        &copy; {new Date().getFullYear()} Fabienne Herion{lineBreak}Autorin | <Link to={'/imprint'}>Impressum</Link> |{' '}
        <Link to={'/privacy'}>Datenschutz</Link>
      </span>
      <div
        className={`going-strong ${isShown}`}
        onMouseEnter={() => setIsShown('shown')}
        onMouseLeave={() => setIsShown('')}
      >
        Going strong for {togetherYears} {togetherYears === 7 ? 'verflixte' : ''} years.&nbsp;&nbsp;
        <FontAwesomeIcon icon={faHeart} />
      </div>
    </div>
  );
};
